import React from "react";
import { Container, Paragraph, Title } from "./style";
import { Wrapper } from "../common/style";
import styled from "styled-components";

const Learning: React.FC = () => {
  return (
    <Wrapper id="learning">
      <Container>
        <Title color="primary" variant="h4">
          How Does Ako Maps Help You Learn?
        </Title>
        <Paragraph variant="body1">Ako Maps is an interactive platform for thinking and learning.</Paragraph>
        <Paragraph variant="body1">
          At first glance an Ako Map looks like a very advanced mind map, but the software comes with a whole toolbox of
          additional features.
        </Paragraph>
        <Paragraph variant="body1">
          For example, you can…
          <List>
            <li>Link video or audio files to a map to see different views of the map as the video plays,</li>
            <li>Test or reinforce learning with quizzes at the end of your presentations,</li>
            <li>Automatically generate ‘smart’ flashcards from the topics in your Ako Map,</li>
            <li>Test your ability to remember your map with the ‘Recall’ feature,</li>
            <li>…and much more!</li>
          </List>
        </Paragraph>
      </Container>
    </Wrapper>
  );
};

const List = styled.ul`
  z-index: 1;
  & li {
    display: list-item;
  }
`;
export default Learning;
