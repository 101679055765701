import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import Footer from "../components/sections/footer";
import theme from "@studytools/core/ui/theme/muiTheme";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ThemeProvider } from "@material-ui/core";
import Integrated from "../components/about/Integrated";
import Learning from "../components/about/Learning";
import NameOrigin from "../components/about/NameOrigin";
import TeamBehind from "../components/about/TeamBehind";

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="About" description="About Ako Map Cognitive Performance Labs" />
      <Navigation />
      <ThemeProvider theme={theme}>
        <Integrated />
        <Learning />
        <NameOrigin />
        <TeamBehind />
      </ThemeProvider>
      <Footer />
    </Layout>
  );
};

export default AboutPage;
